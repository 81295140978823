
























































































































































































































































































































    .el-dialog__wrapper ::v-deep .el-dialog__body{
      padding-top: 0 !important;
     
    }
 .el-steps ::v-deep .el-step__icon-inner{
 font-size: 40px !important;

}
 
.title{
    font-size: 14px !important;
    font-weight: bold;
    
}
.title1{
  font-size: 12px !important;
    margin-top:4px ;
  color: #999;
}
